@font-face {
  font-family: 'SegoeUI';
  src: url('segoe_ui.eot');
  src: url('segoe_ui.eot?#iefix') format('embedded-opentype'), url('segoe_ui.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SegoeUI';
  src: url('segoe_ui_gras.eot');
  src: url('segoe_ui_gras.eot?#iefix') format('embedded-opentype'), url('segoe_ui_gras.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SegoeUI';
  src: url('segoe_ui_semibold.eot');
  src: url('segoe_ui_semibold.eot?#iefix') format('embedded-opentype'), url('segoe_ui_semibold.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI Bold';
  src: url('segoe-ui_bold.ttf');
  font-style: normal;
  font-weight: 700;
}

@font-face {
    font-family: 'Segoe UI Classic';
    src: url('segoe-ui-classic.ttf');
    font-style: normal;
    font-weight: 200;
}
